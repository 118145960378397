import React from 'react';

import {
    Datagrid,
    List,
    TextField,
    DateField,
    FunctionField,
} from 'react-admin';

import {
    BulkActionButtons,
    EditButtonCustom,
} from '@src/custom';

import { formatMoney } from '@src/utils/moneyHelpers';

import FilterForm from './FilterForm';
import { source } from './data';

const BrandsList = (props) => (
    <List
        {...props}
        title="Agencies"
        filters={<FilterForm />}
        sort={{ field: source.createdAt, order: 'DESC' }}
        hasCreate={false}
        bulkActionButtons={<BulkActionButtons resource="users" {...props} />}
    >
        <Datagrid>
            <TextField
                label="Name"
                source={source.name}
            />
            <TextField
                label="Main ID"
                source={source.mainId}
            />
            <TextField
                label="Agents Amount"
                source={source.agentsCount}
            />
            <FunctionField
                label="Premiere Access"
                source={source.premierAccess}
                render={
                    (record) => {
                        if (!record.premier_access) {
                            return null;
                        }

                        return 'Yes';
                    }
                }
            />
            <FunctionField
                label="MDF Allocation"
                source={source.mdfAllocationCents}
                render={
                    (record) => {
                        if (!record.mdf_allocation) {
                            return null;
                        }

                        return formatMoney(record.mdf_allocation);
                    }
                }
            />
            <FunctionField
                label="MDF Used"
                source={source.mdfUsedCents}
                render={
                    (record) => {
                        if (!record.mdf_used) {
                            return null;
                        }

                        return formatMoney(record.mdf_used);
                    }
                }
            />
            <FunctionField
                label="MDF Remaining"
                render={
                    (record) => {
                        if (!record.mdf_remaining) {
                            return null;
                        }

                        return formatMoney(record.mdf_remaining);
                    }
                }
            />
            <DateField
                label="Created At"
                source={source.createdAt}
            />
            <EditButtonCustom key="edit-btn" resource="co_branded_videos" {...props} />
        </Datagrid>
    </List>
);

export default BrandsList;
