const formatMoney = (money) => {
    const currency = money.currency_iso || 'USD';
    return fromMoney(money).toLocaleString('en-US', { style: 'currency', currency });
};

const fromMoney = (money) => {
    const cents = Number(money.cents || 0);
    return (cents / 100.0);
};

const toMoney = (amount) => {
    const money = { cents: Number(amount) * 100, currency: 'USD' };
    return money;
};

export {
    formatMoney,
    fromMoney,
    toMoney,
};
