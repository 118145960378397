import React from 'react';
import PropTypes from 'prop-types';

import { NumberInput } from 'react-admin';
import { fromMoney, toMoney } from '@src/utils/moneyHelpers';

const MoneyInput = ({ source, ...props }) => (
    <NumberInput
        {...props}
        source={source}
        format={fromMoney}
        parse={toMoney}
        inputProps={{
            min: 0,
        }}
    />
);

MoneyInput.propTypes = {
    source: PropTypes.string.isRequired,
};

export default MoneyInput;
