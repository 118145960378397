const source = {
    id: 'id',
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    phoneNumber: 'phone_number',
    address: 'address',
    addressLine2: 'address_line_2',
    city: 'city',
    stateId: 'state_id',
    zipCode: 'zip_code',
    agencyFirstName: 'agency_first_name',
    agencyLastName: 'agency_last_name',
    agencyEmail: 'agency_email',
    agencyPhoneNumber: 'agency_phone_number',
    agencyWebsite: 'agency_website',
    agencyName: 'agency_name',
    status: 'status',
    requestedAt: 'requested_at',
    completedAt: 'completed_at',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
    itemsRequestedCount: 'items_requested_count',
    downloadUrl: 'download_url',
};

const requestStatuses = [
    {
        id: 'created',
        name: 'New',
    },
    {
        id: 'pending',
        name: 'Pending',
    },
    {
        id: 'approved',
        name: 'Approved',
    },
    {
        id: 'rejected',
        name: 'Rejected',
    },
    {
        id: 'sent',
        name: 'Sent',
    },
];

export {
    source,
    requestStatuses,
};
