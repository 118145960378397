const source = {
    name: 'name',
    mainId: 'main_id',
    agentsCount: 'agents_count',
    mdfAllocation: 'mdf_allocation',
    mdfAllocationCents: 'mdf_allocation_cents',
    mdfUsed: 'mdf_used',
    mdfUsedCents: 'mdf_used_cents',
    mdfRemaining: 'mdf_remaining',
    notes: 'notes',
    premierAccess: 'premier_access',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
};

const premierAccessFilterTypes = [
    { id: '', name: 'All' },
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
];

export {
    source,
    premierAccessFilterTypes,
};
